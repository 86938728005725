import React from 'react'
import styled from 'styled-components'
import { useTranslation } from "react-i18next"
import { Button } from '../Atoms/Button'
import bgProtocol from '../../assets/images/bg-protocol.png'

const CtaContainer = styled.section`
  background-color: #EEEEEE;
  background-image: url( ${bgProtocol} );
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  font-family: 'Radley', serif;
  margin-top: 5rem;
  padding: 2rem 0;

  @media(max-width: 1024px) {
    background-image: url('');
  }
`

const Title = styled.h2`
  color: #313131;
  font-family: 'Radley', serif;
  font-size: 41px;
`

const Text = styled.p`
  color: #707070;
  font-family: 'Lato', serif;
  font-size: 17px;
`

const HelpText = styled.span`
  color: #B7B9B8;
  display: block;
  font-family: 'Lato', serif;
  font-size: 15px;
  margin: 1rem 0;
`

export const Cta = () => {
  const [t] = useTranslation('common')

  return (
    <CtaContainer>
      <div className="container">
        <div className='columns py-5 px-3 mt-5'>
          <div className="column is-10 is-offset-2">
            <Title>{t('home.ctaTitle')}</Title>
            <Text>{t('home.ctaText')}</Text>
            <HelpText>{t('home.ctaHelpText')}</HelpText>
            <a target="_blank" title='Ir a comprar' href="https://shop.do-co.mx" rel="noopener noreferrer">
              <Button className={'button d-block d-sm-inline-block mx-auto '} text="Descargar" variant='outline-primary'>
              </Button>
            </a>
          </div>
        </div>
      </div>
    </CtaContainer>
  )
}