import React from 'react'
import styled from 'styled-components'

const Buttons = styled.button`
  border: 1px solid #313131;
  border-radius: 21px;
  cursor: pointer;
  font-family: 'Lato', Regular;
  font-size: 15px;
  letter-spacing: 0.02px;
  opacity: 1;
  padding: 12px 24px;
  transition: opacity 300ms;

  &:hover {
    opacity: .5;
  }
`

const Images = styled.img`
  width: 1rem;
`

export const Button = ({text}) => {
  return (
    <>
      <Buttons className='is-flex is-align-items-center'>
        <Images alt='' className='mx-2' src={require(`../../assets/icons/icon-download.svg`).default} />
        {text}
      </Buttons>
    </>
  )
}