import React from 'react'
import styled from 'styled-components'
import bgWeUs from '../../assets/images/weus.png'
import { useTranslation } from "react-i18next"
import { Cards } from './Cards'

const Subtitle = styled.h2`
  color: #707070;
  font-family: 'Radley', serif;
  font-size: 29px;
`

const Paragraph = styled.p`
  font-family: 'Lato', sans-serif;
  font-size: 17px;
`

const WhiteWall = styled.div`
  padding: 0 32px 32px;
`

export const Sections = () => {
  const [t] = useTranslation('common')

  return (
    <>
      <section>
        <div className='py-5 my-5' id='aboutUs'></div>
        <div className="container columns my-0">
          <div className={(window.outerWidth <= 450) ? 'is-hidden-mobile' : 'is-block is-offset-5'}>
            <img alt="" src={bgWeUs} />
          </div>
          <WhiteWall className='column is-7 has-background-white'>
            <Subtitle className='pb-5'>{t('home.aboutUsTitle')}</Subtitle>
            <Paragraph>{t('home.aboutUsDesc')}</Paragraph>
          </WhiteWall>
        </div>
      </section>

      <Cards />
    </>
  )
}