import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ElasticCarousel from 'react-elastic-carousel'

const ListItem = styled.li`
  color: #313131;
  cursor: pointer;
  font-family: 'Radley', serif;
  font-size: 17px;
  font-weight: 100;
  opacity: 1;
  transition: 300ms opacity;

  &:hover {
    opacity: .5;
  }
`

const BodyDesc = styled.div`
  padding: 14px 20px 14px 32px;
  white-space: break-spaces;
`

const Message = styled.article.attrs({
  className: 'message',
})`
    background-color: white;
`

const MessageHeader = styled.article.attrs({
  className: 'message-header',
})`
    background-color: white;
`

const MessageBody = styled.ul.attrs({
  className: 'message-body',
})`
    background-color: white;
    border: 0;
`

const ModalBackground = styled.ul.attrs({
  className: 'modal-background',
})`
    background-color: white;
`

const CrossIcon = styled.button.attrs({
  className: 'delete',
})`
    background-color: white;

    &::after,
    &::before {
      background-color: #313131;
      border-radius: 4px;
    }

    &::after {
      height: 100%;
      width: 5px;
    }

    &::before {
      height: 5px;
      width: 100%;
    }

    &:hover {
      background: transparent;
      opacity: .8;
    }
`

export const Modal = ({ onClose, selectedValue, open, itemsCarousel, multimodal, description }) => {
  let carousel = null

  const handleClose = () => {
    onClose(selectedValue);
  };

  const goto = (value) => {
    carousel.goTo(value)
  }

  return (
    <>
      {!open
        ? null
        :
        <div className={`modal ${open ? 'is-active' : ''}`} onClose={handleClose}>
          <ModalBackground onClick={handleClose}></ModalBackground>
          <div className="modal-content">
            <Message>
              <MessageHeader className="is-flex is-justify-content-flex-end">
                <CrossIcon aria-label="Eliminar" className="delete" onClick={handleClose}></CrossIcon>
              </MessageHeader>
              <div className="message-body is-flex p-0 m-0">
                {
                  multimodal
                    ?
                    <>
                      <MessageBody className='py-3 px-5 is-block' key={itemsCarousel}>
                        {itemsCarousel[selectedValue].list.map((e, index) =>
                          <ListItem className={`is-block mx-3 mb-3`} key={e} onClick={(e) => {
                            goto(index)
                          }
                          }>
                            {e.listTitle}
                          </ListItem>
                        )}
                      </MessageBody>
                      <ElasticCarousel enableSwipe={false} ref={ref => (carousel = ref)} showArrows={false} pagination={false}>
                        {itemsCarousel[selectedValue].list.map((item, index) => (
                          <div key={index}>
                            <img alt={`Carousel de ${item.title}`} src={require(`../../assets/images/${itemsCarousel[selectedValue].list[index].image}.jpg`)} />
                          </div>
                        ))}
                      </ElasticCarousel>
                    </>
                    : <BodyDesc>{description}</BodyDesc>
                }
              </div>
            </Message>
          </div>
        </div>
      }
    </>
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  list: PropTypes.string,
}

Modal.defaultProps = {
  title: 'No sé encontró título',
  price: '-'
}