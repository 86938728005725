import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Carousel } from './Carousel'
import { Modal } from './Modal'

const Section = styled.section`
  padding-top: 95px;

  @media(max-width: 768px) {
    padding-top: 0;
  }
`

const Subtitle = styled.h2`
  color: #707070;
  font-family: 'Radley', serif;
  font-size: 29px;
`

const Paragraph = styled.p`
  font-family: 'Lato', sans-serif;
  font-size: 17px;
`

const itemsCarousel = [
  {
    id: 0,
    title: 'Índice',
    list: [
      {
        listTitle: 'Índice',
        image: '00_indice/indice'
      }
    ],
    images: ['indice', 'muros', 'indice']
  },
  {
    id: 1,
    title: 'Arquitectura',
    list: [
      {
        listTitle: 'Arquitectura I',
        image: '01_arquitectura/SP_arquitectura_Page_1'
      },
      {
        listTitle: 'Arquitectura II',
        image: '01_arquitectura/SP_arquitectura_Page_1'
      }
    ],
    images: ['indice', 'muros', 'indice']
  },
  {
    id: 2,
    title: 'Demoliciones',
    list: [
      {
        listTitle: 'Demoliciones I',
        image: '02_demoliciones/SP_demoliciones_Page_1'
      },
      {
        listTitle: 'Demoliciones II',
        image: '02_demoliciones/SP_demoliciones_Page_2'
      }
    ],
    images: ['indice', 'muros', 'indice']
  },
  {
    id: 3,
    title: 'Construcción',
    list: [
      {
        listTitle: 'Construcción I',
        image: '03_construccion/SP_construccion_Page_1'
      },
      {
        listTitle: 'Construcción II',
        image: '03_construccion/SP_construccion_Page_2'
      }
    ],
    images: ['indice', 'muros', 'indice']
  },
  {
    id: 4,
    title: 'Iluminación',
    list: [
      {
        listTitle: 'Iluminación I',
        image: '04_iluminacion/SP_iluminacion_Page_1'
      },
      {
        listTitle: 'Iluminación II',
        image: '04_iluminacion/SP_iluminacion_Page_2'
      }
    ],
    images: ['indice', 'muros', 'indice']
  },
  {
    id: 5,
    title: 'Eléctrico',
    list: [
      {
        listTitle: 'Eléctrico I',
        image: '05_electrico/SP_electrico_Page_1'
      },
      {
        listTitle: 'Eléctrico II',
        image: '05_electrico/SP_electrico_Page_2'
      }
    ],
    images: ['indice', 'muros', 'indice']
  },
  {
    id: 6,
    title: 'Acabados',
    list: [
      {
        listTitle: 'Acabados I',
        image: '06_acabados/SP_acabados_Page_1'
      },
      {
        listTitle: 'Acabados II',
        image: '06_acabados/SP_acabados_Page_2'
      }
    ],
    images: ['indice', 'muros', 'indice']
  },
  {
    id: 7,
    title: 'Recubrimientos',
    list: [
      {
        listTitle: 'Recubrimientos I',
        image: '07_recubrimientos/SP_recubrimientos_Page_1'
      },
      {
        listTitle: 'Recubrimientos II',
        image: '07_recubrimientos/SP_recubrimientos_Page_2'
      }
    ],
    images: ['indice', 'muros', 'indice']
  },
  {
    id: 8,
    title: 'Detalles en Baños',
    list: [
      {
        listTitle: 'Detalles en Baños I',
        image: '08_detallesBanos/SP_detallesbanos_Page_1'
      },
      {
        listTitle: 'Detalles en Baños II',
        image: '08_detallesBanos/SP_detallesbanos_Page_2'
      },
      {
        listTitle: 'Detalles en Baños II',
        image: '08_detallesBanos/SP_detallesbanos_Page_3'
      }
    ],
    images: ['indice', 'muros', 'indice']
  },
  {
    id: 9,
    title: 'Detalles Especiales',
    list: [
      {
        listTitle: 'Detalles Especiales I',
        image: '09_detallesEspeciales/SP_detallesespeciales_Page_1'
      },
      {
        listTitle: 'Detalles Especiales II',
        image: '09_detallesEspeciales/SP_detallesespeciales_Page_2'
      },
    ],
    images: ['indice', 'muros', 'indice']
  },
  {
    id: 10,
    title: 'Detalles Tipo',
    list: [
      {
        listTitle: 'Detalles Tipo I',
        image: '10_detallesTipo/SP_detallestipo_Page_1'
      },
      {
        listTitle: 'Detalles Tipo II',
        image: '10_detallesTipo/SP_detallestipo_Page_2'
      },
      {
        listTitle: 'Detalles Tipo III',
        image: '10_detallesTipo/SP_detallestipo_Page_3'
      },
    ],
    images: ['indice', 'muros', 'indice']
  },
  {
    id: 11,
    title: 'Puertas y Ventanas',
    list: [
      {
        listTitle: 'Puertas y Ventanas I',
        image: '11_puertasVentanas/SP_puertasventanas_Page_1'
      },
      {
        listTitle: 'Puertas y Ventanas II',
        image: '11_puertasVentanas/SP_puertasventanas_Page_2'
      }
    ],
    images: ['indice', 'muros', 'indice']
  },
  {
    id: 12,
    title: 'Muebles Adosados',
    list: [
      {
        listTitle: 'Muebles Adosados I',
        image: '12_mueblesAdosados/SP_mueblesadosados_Page_1'
      },
      {
        listTitle: 'Muebles Adosados II',
        image: '12_mueblesAdosados/SP_mueblesadosados_Page_2'
      },
      {
        listTitle: 'Muebles Adosados III',
        image: '12_mueblesAdosados/SP_mueblesadosados_Page_3'
      },
      {
        listTitle: 'Muebles Adosados IV',
        image: '12_mueblesAdosados/SP_mueblesadosados_Page_4'
      },
      {
        listTitle: 'Muebles Adosados V',
        image: '12_mueblesAdosados/SP_mueblesadosados_Page_5'
      },
      {
        listTitle: 'Muebles Adosados VI',
        image: '12_mueblesAdosados/SP_mueblesadosados_Page_6'
      },
      {
        listTitle: 'Muebles Adosados VII',
        image: '12_mueblesAdosados/SP_mueblesadosados_Page_7'
      }
    ],
    images: ['indice', 'muros', 'indice']
  },
  {
    id: 13,
    title: 'Mobiliario',
    list: [
      {
        listTitle: 'Mobiliario I',
        image: '13_mobiliario/SP_mobiliario_Page_1'
      },
      {
        listTitle: 'Mobiliario II',
        image: '13_mobiliario/SP_mobiliario_Page_2'
      }
    ],
    images: ['indice', 'muros', 'indice']
  },
]

export const Slider = () => {
  const [t] = useTranslation('common')
  const [open, setOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState(0)

  const handleClickOpen = (value) => {
    setOpen(true)
    setSelectedValue(value)
  };

  const handleClose = () => {
    setOpen(false)
  };

  return (
    <>
      <Section className='has-text-centered' id='sliderPlans'>
        <Subtitle className='pb-5'>{t('home.plansTitle')}</Subtitle>
        <Paragraph>{t('home.plansDesc')}</Paragraph>
      </Section>
      <Carousel itemsCarousel={itemsCarousel} selectSlide={handleClickOpen} />
      <Modal selectedValue={selectedValue} open={open} onClose={handleClose} itemsCarousel={itemsCarousel} multimodal='true' />
    </>
  )
}