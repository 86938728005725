import React from 'react'
import styled from 'styled-components'
import { NavHashLink } from 'react-router-hash-link'
import { useTranslation } from "react-i18next"

const List = styled.ul`
  color: #707070;
  font-family: 'Radley', serif;
  font-size: 17px;
  list-style: none;
  position: fixed;
  z-index: 1;
  `

const ButtonTranslate = styled.button`
  background: transparent;
  border: none;
  box-shadow: ${props => props.theme.shadow};
  color: ${props => props.theme.color};
  cursor: pointer;
  font-size: 13px;
  font-weight: ${props => props.theme.weight};
`

ButtonTranslate.defaultProps = {
  theme: {
    color: "#707070",
    weight: "400"
  }
}

export const Navigation = () => {
  const [t, i18n] = useTranslation('common')
  const getLanguage = i18n.language
  const theme = {
    color: "#b7b9b8",
    shadow: "0px 2px 0 0",
    weight: "600",
  };

  return (
    <List className='has-text-left pt-4'>
      <ButtonTranslate theme={getLanguage === "es" ? theme : ""} onClick={() => i18n.changeLanguage('es')}>ESP</ButtonTranslate>
      <ButtonTranslate theme={getLanguage === "en" ? theme : ""} onClick={() => i18n.changeLanguage('en')}>ENG</ButtonTranslate>
      <NavHashLink
        activeclassname="selected"
        activestyle={{ color: '#313131' }}
        className='pt-2 is-block is-hidden-mobile'
        smooth={+true}
        to="#aboutUs"
      >{t('home.aboutUs')}</NavHashLink>
      <NavHashLink
        activeclassname="selected"
        activestyle={{ color: '#313131' }}
        className='pt-2 is-block is-hidden-mobile'
        smooth={+true}
        to="#metodology"
      >{t('home.methodology')}</NavHashLink>
      <NavHashLink
        activeclassname="selected"
        activestyle={{ color: '#313131' }}
        className='pt-2 is-block is-hidden-mobile'
        smooth={+true}
        to="#sliderPlans"
      >{t('home.plans')}</NavHashLink>
      <a
        activeclassname="selected"
        activestyle={{ color: '#313131' }}
        className='pt-2 is-block is-hidden-mobile'
        smooth={+true}
        href="https://shop.do-co.mx"
        target="_blank"
        rel="noopener noreferrer"
      >{t('home.tools')}</a>
      <NavHashLink
        activeclassname="selected"
        activestyle={{ color: '#313131' }}
        className='pt-2 is-block is-hidden-mobile'
        smooth={+true}
        to="#contact"
      >{t('home.contact')}</NavHashLink>
    </List>
  )
}