import React from 'react'
import { Slider } from '../Molecules/Slider'
import { FooterSection } from '../Organisms/Footer'
import { HeaderSection } from '../Organisms/Header'
import { Sections } from '../Organisms/Sections'
import { Cta } from '../Organisms/Cta-pd'

export const Main = () => (
  <>
    <HeaderSection />
    <div className="container">
      <div className='columns'>
        <div className='column is-9 is-offset-3'>
          <Sections />
          <Slider />
        </div>
      </div>
    </div>
    <Cta />
    <FooterSection />
  </>
)