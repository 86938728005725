import React from 'react'
import styled from 'styled-components'
import bgHeader from '../../assets/images/header.png'
import { Navigation } from '../Molecules/Navigation'
import { useTranslation } from "react-i18next"
import Slider from "react-slick";

const HeaderContainer = styled.div`
  background-image: url( ${bgHeader} );
  background-size: cover;
  padding-bottom: 186px;
  padding-top: 30px;
`

const Header = styled.header`
  background: transparent;
  padding-top: 30px;
`

const Logo = styled.img`
  width: 162px;
`

const Title = styled.h1`
  font-family: 'Radley', serif;
  font-size: 41px;
  padding-top: 99px;
`

const Link = styled.a`
  color: #B7B9B8;
  font-family: 'Radley', serif;
  font-size: 17px;
`

const NavigationContainer = styled.div`
  margin-top: 60px;
`

const Images = styled.img`
  width: 1rem;
`

const ContainerTranslate = styled.div`
  height: 25px;
  overflow: hidden;
`

const LinksText = styled.span`
  font-family: 'Lato', serif;
  font-size: 15px;
  letter-spacing: 0.01px;
`

const ParagraphAnimated = styled.p`
  animation: mymove infinite;
  animation-duration: 25s;
  font-family: 'Radley', serif;
  font-size: 17px;
`

const linksInfo = [
  {
    alt: 'Correo de Documentos Constructivos',
    icon: 'icon-email',
    link: 'mailto:info@do-co.mx',
    title: 'info@do-co.mx',
  },
  {
    alt: 'LinkedIn de Documentos Constructivos',
    icon: 'icon-linkedin',
    link: 'https://www.linkedin.com/company/documentos-constructivos/',
    title: 'Documentos_Constructivos',
  },
  {
    alt: 'Behance de Documentos Constructivos',
    icon: 'icon-behance',
    link: 'https://www.behance.net/Do_Co?tracking_source=search_users%7CDoco',
    title: 'do_co.mx',
  },
  {
    alt: 'Instagram de Documentos Constructivos',
    icon: 'icon-instagram',
    link: 'https://www.instagram.com/do_co.mx/',
    title: '@do_co.mx',
  },
]

export const HeaderSection = () => {
  const [t] = useTranslation('common')

  const settings = {
    autoplay: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <HeaderContainer>
      <Header className={'is-flex is-align-items-center is-justify-content-space-between container navbar is-fixed-top ' + (window.outerWidth <= 450 ? 'px-4' : '')}>
        <section>
          <Logo alt='Logotipo de DoCo' src={require('../../assets/icons/do-co-logo.svg').default} />
        </section>
        <section className='has-text-right is-flex is-align-items-baseline'>

        {linksInfo.map((link, i) => (
          <Link className='pr-5 is-flex is-align-items-center' href={link.link} key={i} rel="noopener noreferrer" target="_blank" title='Enviar correo a Documentos Constructivos'>
            <Images alt={link.alt} src={require(`../../assets/icons/${link.icon}.svg`)} />
            <LinksText className={(window.outerWidth <= 450) ? 'is-hidden-mobile' : 'is-block is-offset-5 mx-2'} key={link}>{link.title}</LinksText>
          </Link>
        ))}

        </section>
      </Header>
      <NavigationContainer className='has-text-centered container px-1'>
        <Navigation />
        <Title className='pb-5 has-text-white'>{t('home.title')}</Title>
        <ContainerTranslate>
          <Slider {...settings}>
            <ParagraphAnimated className='has-text-white'>We translate your design into universal graphical language</ParagraphAnimated>
            <ParagraphAnimated className='has-text-white'>デザインをグラフィック言語に翻訳します</ParagraphAnimated>
            <ParagraphAnimated className='has-text-white'>Wir verwandeln Ihr Design in grafisches Erleben</ParagraphAnimated>
            <ParagraphAnimated className='has-text-white'>نحن†نترجم†التصميم†الخاص†بك†إلى†لغة†رسومية</ParagraphAnimated>
            <ParagraphAnimated className='has-text-white'>Nous traduisons votre dessin en langage graphique</ParagraphAnimated>
            <ParagraphAnimated className='has-text-white'>Μεταφράζουμε το σχέδιό σας σε γραφική γλώσσα</ParagraphAnimated>
            <ParagraphAnimated className='has-text-white'>Traducimos tu diseño en lenguaje gráfico</ParagraphAnimated>
          </Slider>
        </ContainerTranslate>
      </NavigationContainer>
    </HeaderContainer>
  )
}