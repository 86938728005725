import React from 'react'
import ElasticCarousel from 'react-elastic-carousel'
import styled from 'styled-components'
import iconLupe from '../../assets/icons/icon-lupe.svg'

const CarouselSlide = styled.div`
  align-items: center;
  display: flex;
  padding: 1rem;
  position: relative;

  &::before {
    align-items: center;
    background: #313131;
    color: #fff;
    content: 'Ver detalle de los planos';
    cursor: pointer;
    display: flex;
    font-family: 'Radley', serif;
    font-weight: 200px;
    font-size: 17px;
    height: 100%;
    justify-content: center;
    opacity: 0;
    position: absolute;
    transition: 300ms;
    width: 100%;
  }

  &:hover::before {
    opacity: .78;
    padding-top: 6rem;
  }

  &:hover::after {
    background: #eee;
    border-radius: 50%;
    content: url(${iconLupe});
    height: 48px;
    margin: auto;
    left: 0;
    padding: 15px;
    position: absolute;
    right: 0;
    width: 48px;
  }
`

const CarouselTitle = styled.span`
  bottom: 0;
  font-family: 'Radley', serif;
  font-size: 20px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
`

export const Carousel = ({ itemsCarousel, selectSlide }) => {
  return (
    <ElasticCarousel
      transitionMs={0}
    >
      {itemsCarousel.map((item, i) => (
        <div key={i}>
          <CarouselSlide onClick={() => { selectSlide(item.id); }} key={i}>
            <img alt={`Carousel de ${item.title}`} src={require(`../../assets/images/${item.list[0].image}.jpg`)} />
          </CarouselSlide>
          <CarouselTitle>{item.title}</CarouselTitle>
        </div>
      ))}
    </ElasticCarousel>
  )
}
