import React from 'react'
import styled from 'styled-components'
import { useTranslation } from "react-i18next"

const Section = styled.div`
  margin-top: 36px;
`

const Subtitle = styled.h2`
  color: #707070;
  font-family: 'Radley', serif;
  font-size: 29px;
`

const Paragraph = styled.p`
  font-family: 'Lato', sans-serif;
  font-size: 17px;
`

const WhiteWall = styled.div`
  padding: 0 61px 32px;
`

export const Cards = () => {
  const [t] = useTranslation('common')
  const e = ['planning', 'development', 'follow']
  const isMobile = window.outerWidth <= 450

  return (
    <>
      <div className='py-5 my-5' id='metodology'></div>
      <section className='is-hidden-mobile'></section>
      {
        e.map((value, i) => {
          return (
            <Section className='animated fadeInRight' key={i}>
              <div className="container columns my-0 is-vcentered">
                <div className="is-offset-5">
                  <img alt="" className={(isMobile) ? 'is-block' : 'is-flex'} src={require(`../../assets/images/${value}.png`)} style={ (isMobile ? {margin: 'auto'} : {margin: '0'}) } />
                </div>
                <WhiteWall className='column is-7 has-background-white'>
                  <Subtitle className={( isMobile ? ' has-text-centered is-block py-5' : 'pb-5' )}>{t(`home.${value}Title`)}</Subtitle>
                  <Paragraph>{t(`home.${value}Desc`)}</Paragraph>
                </WhiteWall>
              </div>
            </Section>
          )
        })
      }
    </>
  )
}